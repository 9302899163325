<template>
  <div class="home">
    <v-container>
      <h1>Welcome to the DIP help page</h1>
      <br />

      <v-row>
        <v-col cols="10">
          <h3>Getting started on the Digital Inception Plan..</h3>
          For an introduction to the DIP please look at the landing page on the
          Automation SharePoint Site from

          <a
            href="https://arup.sharepoint.com/teams/global-design-automation-team/SitePages/Digital-Inception-Plan.aspx"
            target="_blank"
            >Here</a
          >. <br />This includes a short "Introduction" and "How to video" The
          Digital Inception Plan is designed to be a facilitated workshop.
          <br />
          To find trained facilitators in your region please visit your regional
          automation sharePoint site from
          <a
            href="https://arup.sharepoint.com/teams/global-design-automation-team/SitePages/Digital-Inception-Plan.aspx"
            target="_blank"
            >Here</a
          >.
        </v-col>

        <v-col cols="2">
          <v-btn class="backButton" @click="navigate('Home')"></v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3>Are you a Facilitator ?</h3>
          Would you like to become a facilitator? You can access the training on
          Moodle
          <a
            href="https://moodle.arup.com/course/view.php?id=5841%C2%A7ion%3D7"
            target="_blank"
            >Here</a
          >. . <br />
          <br />
          <br />

          <h3>Need to reach out below are the key regional contacts..</h3>
          East Asia: <strong>Thomas Bush</strong><br />
          Australasia: <strong> Sean McGinn </strong><br />
          UKIMEA: <strong>James O'Donnell </strong><br />
          Americas: <strong>Joerg Tonndorf</strong><br />
          Europe: <strong> Rinke Kluwer</strong><br />
          <br /><br />

          <h3>We would love to hear your feedback..</h3>
          If you would like to provide us feedback, whether bugs or new features
          please click
          <a
            href="https://feedback.arup.com/digital-inception-pl/35"
            target="_blank"
            >Here
          </a>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <br />
          <br />
          <br />
          <h3>
            If you have an urgent issue please log a request with ServiceNow
            from
            <a href="https://arup.service-now.com/sp/" target="_blank">Here </a
            >.
          </h3>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {},
  methods: {
    navigate(routeName) {
      console.log(`navigation to ${routeName}...`);
      this.$router.push({ name: `${routeName}` });
    },
  },
};
</script>
